/* SoundscapesSection.css */

.soundscapes-section {
    padding: 80px 70px 80px 200px;
    background: radial-gradient(circle at top left, #D2C0A7, #86827b, #424240); /* Match background to your main theme */
    color: #2c2c2c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.soundscapes-content {
    flex: 1;
    margin-right: 40px; /* Space between text and image */
}

.soundscapes-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c2c2c;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
}

.soundscapes-text {
    font-size: 1.2rem;
    margin-bottom: 25px;
    line-height: 1.6;
    color: #2c2c2c;
}

.soundscapes-list {
    list-style-type: none;
    padding: 0;
}

.soundscapes-list-item {
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
    color: #2c2c2c;
}

.soundscapes-list-item::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #D2C0A7;
    font-size: 1.5em;
}

.soundscapes-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-frame-soundscapes {
    padding: 10px;
    border-radius: 8px;
}

.image-frame img {
    max-width: 100%;
    height: auto;
    display: block;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .soundscapes-section {
        flex-direction: column; /* Stack image and text on top of each other */
        padding: 40px 20px; /* Reduce padding for mobile */
        align-items: center;
    }

    .soundscapes-content {
        margin-right: 0;
        margin-bottom: 30px; /* Add margin between text and image */
        text-align: center; /* Center text on mobile */
    }

    .soundscapes-title {
        font-size: 2rem; /* Reduce font size on mobile */
    }

    .soundscapes-text {
        font-size: 1rem; /* Reduce text size */
    }

    .soundscapes-list-item {
        font-size: 0.9rem; /* Adjust list item font size */
    }

    .soundscapes-image {
        margin-top: 20px;
    }

    .image-frame {
        max-width: 80%; /* Make image smaller on mobile */
    }
}

.footer {
    background-color: #2C2C2C;
    padding: 1rem 0;
}

.footer-text {
    text-align: center;
    font-size: 0.875rem;
    color: #D1D5DB;
}

.privacy-policy-link {
    color: #61dafb; /* Change to your desired link color */
    text-decoration: none; /* Remove underline */
    margin-left: 5px; /* Space between text and link */
    transition: color 0.3s ease; /* Smooth color transition */
}

.privacy-policy-link:hover {
    text-decoration: underline; /* Underline on hover */
    color: #ffffff; /* Change color on hover for better visibility */
}

@media (max-width: 768px) {
    .footer {
        padding: 1rem;
    }

    .footer-text {
        font-size: 0.75rem;
    }
}

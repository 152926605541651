.team-section {
    padding: 80px 200px; /* 90px left/right and 80px top/bottom padding */
    text-align: center;
  }
  
  .team-section-title {
    color: #2C2C2C;
    margin-top: 0;
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Max 3 columns, min width of 300px */
    gap: 30px 20px; /* 30px top/bottom, 20px right/left */
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .team-member {
    background-color: #D2C0A7;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    min-width: 300px; /* Set fixed width for each card */
    margin: 0 auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .team-member-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    margin-right: 15px;
    overflow: hidden; /* Add this line */
  }
  
  .team-member-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-member-info {
    flex-grow: 1;
  }
  
  .team-member-name {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .team-member-role {
    margin: 5px 0 0;
    font-size: 14px;
    color: #2C2C2C;
  }
  
  /* Responsive: Ensure the gap doesn't shrink on smaller screens */
  @media (max-width: 768px) {
    .team-section {
      padding: 60px 20px;
    }
  
    .team-grid {
      grid-template-columns: 1fr;
    }
  
    .team-member {
      min-width: auto;
    }
  }
  
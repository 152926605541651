.header {
  position:sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  height: 60px;
  background-color: #D2C0A7;
  color: #2C2C2C;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  text-decoration: none;
  margin-left: 30px;
  width: 80px;
  height: auto;
  font-family: 'Aloevera Sans';
  font-size: 30px;
  color: #2C2C2C;

  
}

.nav {
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav ul li {
  margin-left: 30px;
}

.nav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  font-weight: 600;
}

.nav ul li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav ul li a:hover::after {
  transform: scaleX(1);
}
@media (max-width: 768px) {
  .header {
    justify-content: center;
    height: auto;
    padding: 10px 0;
  }

  .logo {
    margin: 0;
  }

  .nav {
    display: none;
  }
}
/* ZenPositionSection.css */

.zen-position-section {
    padding: 80px 200px;
    background: radial-gradient(circle at top left, #D2C0A7, #86827b, #424240); /* Match background to your main theme */
    color: #2c2c2c; /* Off-white text color */
    display: flex;
    justify-content: flex-start;
    /* border-top: 2px solid #D2C0A7;
    border-bottom: 2px solid #D2C0A7; */
}

.zen-position-content {
    flex: 1;
    margin-right: 40px; /* Space between text and image */
}

.zen-position-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c2c2c;
    font-family: 'Arial', sans-serif;
    font-weight: 600; /* Slightly bolder for emphasis */
    letter-spacing: 1px;
    text-align: left;
}

.zen-position-text {
    font-size: 1.2rem;
    margin-bottom: 25px;
    line-height: 1.6;
    color: #2c2c2c;
}

.zen-position-list {
    list-style-type: none;
    padding: 0;
}

.zen-position-list-text {
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
    color: #2c2c2c;
}

.zen-position-list-text::before {
    content: '✔'; /* Change bullet point to a checkmark */
    position: absolute;
    left: 0;
    color: #D2C0A7; /* Light wood tone color */
    font-size: 1.5em;
}

.zen-position-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-frame {
    border: 8px solid #D2C0A7; /* Light wood-tone border */
    padding: 10px;
    background-color: #F4F4F4; /* Background for image frame */
    border-radius: 8px;
}

.image-frame img {
    max-width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 768px) {
    .zen-position-section {
        flex-direction: column;
        padding: 60px 30px;
    }

    .zen-position-content {
        margin-right: 0;
        margin-bottom: 40px;
    }

    .zen-position-title {
        font-size: 2rem;
    }

    .zen-position-text {
        font-size: 1rem;
    }

    .image-frame {
        max-width: 100%;
    }
    
    
    
}

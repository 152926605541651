.hero-section {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 160px); /* Adjust based on your header/footer height */
}

.hero-content {
  max-width: 1200px;
  width: 100%; /* Ensure it stretches the full width */
  padding: 0 20px; /* Add padding to prevent the content from touching the edges */
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  align-items: center;
  justify-content: space-between;
}

.hero-text {
  flex: 1;
  padding-right: 40px;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 400px; /* Set a max-width for larger screens */
  height: auto;
  transition: max-width 0.3s ease;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary {
  background-color: #D2C0A7;
  color: #2C2C2C;
}

.btn-secondary {
  background-color: transparent;
  color: #2C2C2C;
  border: 2px solid #D2C0A7;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

/* Media queries for responsiveness */

@media (max-width: 1024px) {
  .hero-image img {
    max-width: 300px; /* Reduce image size on smaller screens */
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column !important; /* Stack image and text */
    text-align: center !important; /* Center text */
  }

  .hero-text {
    padding-right: 0;
    width:100% 
  }

  .hero-image {
    order: -1; /* Move image above text */
    margin-bottom: 20px; /* Add space between image and text */
  }

  .hero-image img {
    max-width: 70%; /* Make image responsive */
  }

  h1 {
    font-size: 2rem; /* Adjust heading size for mobile */
  }

  p {
    font-size: 1rem; /* Adjust paragraph size for mobile */
  }

  .btn {
    width: 100%; /* Make buttons full width on mobile */
  }
}

.features-section {
    padding: 80px 20px;
    /*background-color: rgba(44, 44, 44, 0.1);*/ /* Slightly darker than the gradient */
  }
  
  .features-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 40px;
    color: #2C2C2C;
  }
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .feature-card {
    background-color: rgba(210, 192, 167, 0.2); /* Light wood tone with opacity */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(33.33% - 20px); /* Subtracting 20px to account for gap */
    max-width: 350px;
    min-width: 250px;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #2C2C2C;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: #4A4A4A;
  }
  
  @media (max-width: 1024px) {
    .feature-card {
      width: calc(50% - 15px); /* 2 cards per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .feature-card {
      width: 100%; /* 1 card per row on small screens */
    }
  }
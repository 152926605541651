/* PeltierCoolingSection.css */

.peltier-cooling-section {
    padding: 80px 70px 80px 200px;
    background: radial-gradient(circle at top left, #D2C0A7, #86827b, #424240);
    color: #2c2c2c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.peltier-cooling-content {
    flex: 1;
    margin-right: 40px;
}

.peltier-cooling-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c2c2c;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
}

.peltier-cooling-text {
    font-size: 1.2rem;
    margin-bottom: 25px;
    line-height: 1.6;
    color: #2c2c2c;
}

.peltier-cooling-list {
    list-style-type: none;
    padding: 0;
}

.peltier-cooling-list-item {
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
    color: #2c2c2c;
}

.peltier-cooling-list-item::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #D2C0A7;
    font-size: 1.5em;
}

.peltier-cooling-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-frame-peltier {
    padding: 10px;
    border-radius: 8px;
}

.image-frame-peltier img {
    max-width: 100%;
    height: auto;
    display: block;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .peltier-cooling-section {
        flex-direction: column;
        padding: 40px 20px;
        align-items: center;
    }

    .peltier-cooling-content {
        margin-right: 0;
        margin-bottom: 30px;
        text-align: center;
    }

    .peltier-cooling-title {
        font-size: 2rem;
        text-align: center;
    }

    .peltier-cooling-text {
        font-size: 1rem;
        text-align: center;
    }

    .peltier-cooling-list-item {
        font-size: 0.9rem;
        text-align: center;
    }

    .peltier-cooling-image {
        margin-top: 20px;
    }

    .image-frame-peltier {
        max-width: 80%;
    }
}

.contact-section {
    padding: 64px 16px;
  }
  
  .container {
    max-width: 768px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #2C2C2C;
    margin-bottom: 24px;
  }
  
  .description {
    color: #2C2C2C;
    margin-bottom: 32px;
    font-size: 19px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
  }
  
  .email-input {
    flex-grow: 1;
    max-width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    background-color: #D2C0A7;
    color: #2C2C2C;
    border: 1px solid #2C2C2C;
    border-radius: 4px;
  }
  
  .email-input::placeholder {
    color: #2C2C2C;
  }
  
  .email-input:focus {
    outline: none;
    border-color: #E4E4E4;
    box-shadow: 0 0 0 2px rgba(228, 228, 228, 0.5);
  }
  
  .submit-button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    background-color: #2C2C2C;
    color: #E4E4E4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #E4E4E4;
    color: #2C2C2C;
  }

  .thank-you-message {
    margin-top: 20px;
    color: #2C2C2C;
    font-weight: bold;
    font-size: 18px;
  }
  
  @media (min-width: 640px) {
    .form {
      flex-direction: row;
    }
  
    .email-input {
      max-width: 400px;
    }
  }
  
  @media (min-width: 768px) {
    .contact-section {
      padding: 64px 32px;
    }
  
    .title {
      font-size: 48px;
    }
  }
  
  @media (min-width: 1024px) {
    .contact-section {
      padding: 64px 64px;
    }
  }
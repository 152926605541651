.privacy-policy {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    color: #2C2C2C;
}

.privacy-policy h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.privacy-policy h2 {
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 10px;
}

.privacy-policy p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.privacy-policy ul {
    margin-left: 20px;
    list-style-type: disc;
}

.privacy-policy ul li {
    margin-bottom: 10px;
}

